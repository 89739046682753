/* -------------------------------------------
    Layout
---------------------------------------------- */
.container,
.container-fluid {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;
}

.container.wrapper {
  max-width: 100%;
}
@include mq(xs, max) {
  .container,
  .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.container-fluid {
  @include css(max-width, base, _container-fluid-width);
}
.container {
  @include css(max-width, base, _container-width);
}
.divider {
  display: inline-block;
  vertical-align: middle;
  margin-right: 1.5rem;
  height: 2.5rem;
  width: 1px;
  background-color: $border-color;
}

.coupon-discount-bar {
  background-color: #17b31b;
  justify-content: center;
  z-index: 1000;
  width: 100%;

  @media (min-width: 575px) {
    max-width: 52rem !important;
  }
}

.animate {
  animation-name: slideIn;
  animation-duration: 1s;
}

@keyframes slideIn {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

// @media (min-width: 480px) {
//   .coupon-discount-bar {
//     bottom: 72px !important;
//   }
// }

.coupon-discount {
  .coupon-discount-text {
    letter-spacing: 1.5px;
    font-weight: 400;
    color: white;
    text-align: center;
    font-size: 12px !important;
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9999;
}

.modal-content {
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  width: 300px;
  max-width: 90%;
  max-height: 90%;
  overflow: auto;
  text-align: center;
  position: relative;
  padding: 16px;
}

.close-button {
  width: 100%;
  background-color: #c0ca7f;
  border: none;
  border-top: 1px solid #dddddd;
  padding: 10px;
  color: #ffffff;
  font-weight: bold;
  cursor: pointer;
  font-size: 15px;
}

.modal-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
}

.modal-title {
  color: #8d8d8d;
  text-align: center;
  font-family: Manrope;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media (max-width: 450px) {
    font-size: 16px;
  }
}

.modal-amount {
  color: #c0ca7f;
  font-size: 32px;
  margin-top: -12px;
}

.modal-savings {
  color: #555555;
  font-size: 15px;
  margin-top: -15px !important;
}

.close-icon {
  position: absolute;
  right: 0;
  top: 0;
  border: none;
  margin: 5px;
  cursor: pointer;
}
